.card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 9999;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
}

.balance-label {
  font-size: 15px;
  text-transform: uppercase;
  color: #888;
  margin-bottom: 15px;
}

.balance {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.balance-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.balance-value {
  font-size: 16px;
  font-weight: bold;
}

.close-button {
  background-color: rgb(3, 201, 215);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 139px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.close-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.separator {
  height: 1px;
  width: 100%;
  background-color: #ccc;
  margin: 16px 0;
}
